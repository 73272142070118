import {PRICES} from '../../prices'

export const OT_ROBOT_URL = 'https://shop.opentrons.com/ot-2-robot/'

export const CHERRYPICKING_PROTOCOL_URL =
  'https://protocols.opentrons.com/protocol/cherrypicking/'

export const BENEFIT_DATA = {
  benefitList: [
    'Avoids human error / picking the wrong well',
    'Increases speed of procedure',
    'Assures maximum pipetting accuracy',
    'Saves time, doing repetitive pipetting for you',
  ],
  benefitContent:
    'Automating cherry picking offers many benefits over traditional manual protocols, such as:',
}

export const LABWARE_URL = 'https://labware.opentrons.com/'

export const OPENTRONS_TIPS_URL =
  'https://shop.opentrons.com/universal-filter-tips/'

export const WELL_MIRCOPLATES_URL =
  'https://labware.opentrons.com/?category=wellPlate'

export const MATERIAL_DATA = [
  {
    url: 'https://shop.opentrons.com/ot-2-robot/',
    imgSrc: require('../../images/application/materials/opentrons_ot2.jpg')
      .default,
    name: 'OT-2 Liquid Handling Robot',
    price: 'Starting at under $10,000.00',
  },
  {
    url: 'https://shop.opentrons.com/single-channel-electronic-pipette-p20/',
    imgSrc: require('../../images/application/materials/single_pipette.jpg')
      .default,
    name: 'Single Channel Pipette',
    price: PRICES.SingleChannelPipette,
  },
  {
    url: 'https://opentrons.com/ot-app/',
    imgSrc: require('../../images/application/materials/opentrons_app.jpg')
      .default,
    name: 'Opentrons OT-2 Run App',
  },
  {
    url: 'https://shop.opentrons.com/universal-filter-tips/',
    imgSrc: require('../../images/application/materials/filter_tips.jpg')
      .default,
    name: 'Opentrons Tips',
    price: 'Starting at $300.00',
  },
]

export const WORKFLOW_DATA = [
  {
    imgSrc: require('../../images/application/workflow/step-3_200x150.png')
      .default,
    stepNumber: '1',
    name: 'Place reagents onto robot',
    content: 'Load reagents and labware onto the deck of the OT-2.',
  },
  {
    imgSrc: require('../../images/application/workflow/step-2_200x150.png')
      .default,
    stepNumber: '2',
    name: 'Create excel file',
    content:
      'Create excel file indicating which wells in your plate should be picked. Upload as CSV to our Protocol Library and download protocol.',
  },
  {
    imgSrc: require('../../images/application/workflow/step-1_200x150.png')
      .default,
    stepNumber: '3',
    name: 'Calibrate and activate protocol',
    content: 'Confirm pipetting locations and hit “run.”',
  },
  {
    imgSrc: require('../../images/application/workflow/run_your_assay.png')
      .default,
    stepNumber: '4',
    name: 'Run your assay',
    content: 'Run your cherry picking protocol.',
  },
]

export const MATERIAL_BULLET_POINT_DATA = [
  '96-or 384-well microplates',
  'Opentrons tips',
  'CSV file',
  'Cherry picking protocol',
]

export const OVERVIEW_DATA = {
  workflowCompatibility:
    'Cherry picking can be used for the following applications:',
  listBulletpoint: [
    'High throughput screening',
    'Small molecule drug discovery',
    'Microbiology transfection',
    'Picking successful mutants',
  ],
}

export const RESOURCE_NOTE_DATA = [
  {
    url: 'https://opentrons.com/publications/OT-2-Pipette-White-Paper-GEN1.pdf',
    noteType: 'Technical Note',
    name: 'Opentrons Pipette White Paper (GEN1)',
    iconName: 'pdf-download',
  },
  {
    url: 'https://opentrons.com/publications/OT-2-Pipette-White-Paper.pdf',
    noteType: 'Technical Note',
    name: 'Opentrons Pipette White Paper (GEN2)',
    iconName: 'pdf-download',
  },
  {
    url: CHERRYPICKING_PROTOCOL_URL,
    noteType: 'Protocol',
    name: 'Cherry picking',
    iconName: 'protocol',
  },
]

export const RELATED_CONTENT_DATA = [
  {
    url: 'https://blog.opentrons.com/the-power-of-open-source-in-biology-damp-labs-10k-biofoundry/',
    readInfo: 'BLOG - 6 MIN READ',
    title: `The Power of Open Source in Biology—DAMP Lab’s $10k Biofoundry`,
    content:
      'The DAMP Lab is a fledgling biofoundry within the new Biological Design Center at Boston University.',
  },
  {
    url: 'https://blog.opentrons.com/ngs-automation/',
    readInfo: 'BLOG - 4 MIN READ',
    title: `5 Reasons to Automate your NGS Workflow`,
    content:
      'With greater speed, lower costs, and higher throughput than first generation methods, next generation sequencing (NGS) has catapulted biological research into a new era.',
  },
]

export const VIDEO_TITLE = 'Automating Cherry Picking With The OT-2 (00:57)'

export const USER_VIDEO = 'https://player.vimeo.com/video/349541625'

export const MENU_DATA = {
  menuData: {
    links: [
      'overview',
      'benefits',
      'workflow',
      'opentrons materials',
      'notes',
      'resources',
    ],
    url: '/automated-cherrypicking/',
    workflow: 'Interested in automating your cherry picking?',
  },
}

export const CSV_FILE =
  'https://docs.google.com/spreadsheets/d/10ts0zdoUOHlwkElJi6KkaghWmsN4PPZqF9iJoXzGmGA/edit#gid=0'
