// @flow

import * as React from 'react'
import {Link} from 'gatsby'

import CustomerSpotlight from '../homepage/CustomerSpotlight'

import {
  Overview,
  AppLink,
  Benefit,
  Instructions,
  OpentronsMaterial,
  Notes,
  Resources,
  Related,
  StickyMenu,
  VideoHero,
  LightBox,
  Hero,
} from '../../ui-components'

import {
  OT_ROBOT_URL,
  CHERRYPICKING_PROTOCOL_URL,
  WELL_MIRCOPLATES_URL,
  OPENTRONS_TIPS_URL,
  BENEFIT_DATA,
  WORKFLOW_DATA,
  MATERIAL_DATA,
  LABWARE_URL,
  RESOURCE_NOTE_DATA,
  RELATED_CONTENT_DATA,
  VIDEO_TITLE,
  USER_VIDEO,
  MENU_DATA,
  OVERVIEW_DATA,
  CSV_FILE,
} from './cherrypicking-data'

import HERO_VIDEO from './assets/cherrypicking-video-still.jpg'

import * as styles from './Cherrypicking.module.css'

export const CONTENT_INFO = (
  <p className={styles.p}>
    Cherry picking, or “hit picking,” requires constant multitasking in order to
    ensure the correct well is pipetted. Performing this method by hand is
    labor-intensive and can be error-prone—wasting time, effort, and even
    samples. <br />
    <br />
    The need for 100% correct well selection is why an increasing number of
    scientists are automating cherry picking with the{' '}
    <AppLink url={OT_ROBOT_URL}>Opentrons OT-2 liquid handling robot.</AppLink>
    <br />
    <br />
    To create your cherry picking run, create an excel spreadsheet indicating
    which wells in your plate should be picked and upload that to the{' '}
    <AppLink url={CHERRYPICKING_PROTOCOL_URL}>
      cherry picking protocol on the Protocol Library
    </AppLink>{' '}
    as a CSV to create the cherry picking protocol you need.{' '}
  </p>
)

export const NOTE_DATA = [
  {
    name: 'Labware',
    note: (
      <p>
        The OT-2 supports various labware for cherry picking. While the labware
        you use may vary, we recommend using one of our supported 96-well or
        384-well microplates with our protocol because that’s what we used to
        optimize and verify it. For more information on supported labware,
        please see our <AppLink url={LABWARE_URL}>Labware Library</AppLink>.
      </p>
    ),
  },
  {
    name: 'Pipettes',
    note: (
      <p>
        Ensure that your OT-2 is fitted with single-channel pipettes for the
        volumes you will need to work with. Only single-channel pipettes are
        supported for this method. Please review our Opentrons Pipette White
        Papers linked below for more information on our pipettes and pipetting
        volumes as low as 1 µL.
      </p>
    ),
  },
]

// eslint-disable-next-line no-unused-vars
const MATERIAL_BULLET_POINT_DATA = [
  <p key={'1'}>
    <AppLink url={WELL_MIRCOPLATES_URL}>96-or 384-well microplates</AppLink>
  </p>,
  <p key={'2'}>
    <AppLink url={OPENTRONS_TIPS_URL}>Opentrons tips</AppLink>
  </p>,
  <p key={'3'}>
    <AppLink url={CSV_FILE}>CSV file</AppLink>
  </p>,
  <p key={'4'}>
    <AppLink url={CHERRYPICKING_PROTOCOL_URL}>Cherry picking protocol</AppLink>
  </p>,
]

const relatedButtonProps = [
  {
    children: 'Nucleic Acid Purification',
    Component: Link,
    to: '/nucleic-acid-cleanup',
    outline: true,
  },
  {
    children: 'PCR Sample Prep',
    Component: Link,
    to: '/pcr-sample-prep',
    outline: true,
  },
  {
    children: 'Serial Dilution',
    Component: Link,
    to: '/automated-serial-dilution',
    outline: true,
  },
]

const TITLE = 'Automated Cherry Picking'

export default function Cherrypicking() {
  const [videoOpen, setVideoOpen] = React.useState(false)
  return (
    <>
      <Hero
        application={TITLE}
        backgroundClassName={styles.background_image}
        h2ClassName={styles.hero_h2}
        textClassName={styles.hero_text}
      />
      <div className={styles.container}>
        <StickyMenu {...MENU_DATA} />
        <div className={styles.content_container}>
          <VideoHero
            onClick={() => setVideoOpen(true)}
            videoStill={HERO_VIDEO}
            title={VIDEO_TITLE}
          />
          <Overview content={CONTENT_INFO} {...OVERVIEW_DATA} />
          <Benefit {...BENEFIT_DATA} />
          <Instructions workflowItems={WORKFLOW_DATA} />
          <OpentronsMaterial
            materialItems={MATERIAL_DATA}
            /* bulletItems={MATERIAL_BULLET_POINT_DATA} TODO uncomment this once the data is available */
          />
          <Notes noteItems={NOTE_DATA} />
          <Resources ResourceData={RESOURCE_NOTE_DATA} />
        </div>
      </div>
      {videoOpen && (
        <LightBox
          videoSrc={USER_VIDEO}
          onCloseClick={() => setVideoOpen(false)}
        />
      )}
      <Related
        relatedContent={RELATED_CONTENT_DATA}
        buttons={relatedButtonProps}
      />
      <CustomerSpotlight whiteBg={true} />
    </>
  )
}
