import React from 'react'
import Page from '../components/Page'
import SEO from '../components/seo'
import Cherrypicking from '../components/Cherrypicking'

const CherrypickingPage = () => (
  <Page>
    <SEO
      title="Automated Cherrypicking | Opentrons Lab Automation"
      description="Automating cherry picking offers many benefits over traditional manual protocols, such as: avoids human error / picking the wrong well, Increases speed, assures accuracy,  saves time, and more."
    />
    <Cherrypicking />
  </Page>
)

export default CherrypickingPage
